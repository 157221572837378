import type { FileUploadUploaderEvent } from "primevue/fileupload";
import type { Location } from "~/composables/useCmsMedia";

const { user } = useUserStore();

export interface UserInfo {
  id?: string;
  email?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  country?: {
    id: string;
    alpha2Code: string;
    alpha3Code: string;
    numericCode: number;
    englishShortName: string;
    createdAt: string;
    updatedAt: string;
  };
  socialProvider?: string | null;
  yearsOfExperience?: string;
  professionalTrack?: string;
  professionalTrackOther?: string;
  specialty?: string;
  specialtyOther?: string;
  profilePicture?: Location | undefined | null;
}

export enum USER_PROFILE_SECTION {
  PERSONAL_INFO = 'personalInfo',
  ACCOUNT_INFO = 'accountInfo',
  YOUR_SPECIALTY = 'yourSpecialty',
  YOUR_PROFESSION = 'yourProfession',
  YOUR_YEARS_OF_EXPERIENCE = 'yourYearsOfExperience',
}

export type USER_PROFILE_SECTION_TYPE =
  | USER_PROFILE_SECTION.PERSONAL_INFO
  | USER_PROFILE_SECTION.ACCOUNT_INFO
  | USER_PROFILE_SECTION.YOUR_SPECIALTY
  | USER_PROFILE_SECTION.YOUR_PROFESSION
  | USER_PROFILE_SECTION.YOUR_YEARS_OF_EXPERIENCE;

export default function useAccount () {
  const loading = ref<{
    userInfo: boolean;
    personalInfo: boolean;
    accountInfo: boolean;
    yourSpecialty: boolean;
    yourProfession: boolean;
    yourYearsOfExperience: boolean;
    password: boolean;
  }>({
    userInfo: false,
    personalInfo: false,
    accountInfo: false,
    yourSpecialty: false,
    yourProfession: false,
    yourYearsOfExperience: false,
    password: false,
  });

  const userInfo = ref<UserInfo>();
  const userInfoStaged = ref<UserInfo>();

  const syncUserInfo = (userInfoUpdated: UserInfo | null = null) => {
    if (userInfoUpdated) {
      userInfoStaged.value = { ...userInfoStaged.value, ...userInfoUpdated };
    } else {
      userInfoStaged.value = JSON.parse(JSON.stringify(userInfo.value));
    }
  };

  const fetchUserInfo = async () => {
    return await useErrorHandling(async () => {
      loading.value.userInfo = true;

      const response = await $fetch<UserInfo>('/api/auth/user/profile-information');
      if (!response) {
        throw new Error('Invalid response from server.');
      }

      userInfo.value = response;
      syncUserInfo();

      loading.value.userInfo = false;
    }, 'Fetching user profile information failed');
  };

  const updateUserInfo = async (
    section: Array<USER_PROFILE_SECTION_TYPE>,
  ) => {
    return await useErrorHandling(async () => {
      let body: any = {};

      section.forEach((item: USER_PROFILE_SECTION_TYPE) => {
        loading.value[item] = true;

        if (userInfo.value) {
          switch (item) {
            case USER_PROFILE_SECTION.PERSONAL_INFO:
              body = {
                ...body,
                firstName: userInfo.value.firstName,
                lastName: userInfo.value.lastName,
                country: userInfo.value.country?.alpha2Code,
              };
              break;

            case USER_PROFILE_SECTION.YOUR_SPECIALTY:
              body = {
                ...body,
                specialty: userInfo.value.specialty,
                specialtyOther: userInfo.value.specialtyOther,
              };
              break;

            case USER_PROFILE_SECTION.YOUR_PROFESSION:
              body = {
                ...body,
                professionalTrack: userInfo.value.professionalTrack,
                professionalTrackOther: userInfo.value.professionalTrackOther,
              };
              break;

            case USER_PROFILE_SECTION.YOUR_YEARS_OF_EXPERIENCE:
              body = {
                ...body,
                yearsOfExperience: userInfo.value.yearsOfExperience,
              };
              break;
          }
        }
      });

      await useAuthenticatedFetch('/api/auth/user/profile-information', {
        method: 'PUT',
        credentials: 'include',
        body,
      });

      syncUserInfo(body as UserInfo);

      if (user.value) {
        if (userInfo.value?.firstName) {
          user.value.firstName = userInfo.value.firstName;
        }
        if (userInfo.value?.lastName) {
          user.value.lastName = userInfo.value.lastName;
        }
      }

      section.forEach((item: USER_PROFILE_SECTION_TYPE) => {
        loading.value[item] = false;
      });
    }, 'Updating user profile info failed');
  };

  const changePassword = async (
    currentPassword: string,
    password: string,
  ) => {
    loading.value.password = true;

    await useAuthenticatedFetch('/api/auth/user/password', {
      method: 'PUT',
      credentials: 'include',
      body: {
        currentPassword,
        password,
      },
    }).finally(() => {
      loading.value.password = false;
    });
  };

  const uploadProfilePicture = async (
    event: FileUploadUploaderEvent,
  ) => {
    if (!userInfo.value) {
      return;
    }

    const file = Array.isArray(event?.files) ? event.files[0] : null;
    if (!file) {
      throw new Error('No file selected');
    }

    const formData = new FormData();
    formData.append('profilePicture', file);

    const response = await useAuthenticatedFetch<{
          message: string;
          profilePicture: Location;
        }>('/api/auth/user/profile-picture', {
          method: 'POST',
          credentials: 'include',
          body: formData,
        });

    if (!response.data.value) {
      throw new Error('Invalid response from server');
    }

    userInfo.value.profilePicture = response.data.value.profilePicture;
    syncUserInfo({ profilePicture: userInfo.value.profilePicture });
    if (user.value) {
      user.value.profilePicture = userInfo.value.profilePicture;
    }
  };

  const removeProfilePicture = async () => {
    await useErrorHandling(async () => {
      if (!userInfo.value) {
        return;
      }

      await useAuthenticatedFetch('/api/auth/user/profile-picture', {
        method: 'DELETE',
        credentials: 'include',
      });

      userInfo.value.profilePicture = null;
      syncUserInfo({ profilePicture: userInfo.value.profilePicture });
      if (user.value?.profilePicture) {
        user.value.profilePicture = null;
      }

    }, 'Removing user profile picture failed');
  };

  return {
    userInfo,
    userInfoStaged,
    loading,

    fetchUserInfo,
    updateUserInfo,
    changePassword,
    uploadProfilePicture,
    removeProfilePicture,
  };
}
